* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Brooklyn', sans-serif;
  -webkit-tap-highlight-color: transparent;
}

@font-face {
  font-family: 'GeomLight';
  src: local('GeomLight'), url(./fonts/geom/Geom_Light.ttf) format('truetype');
}

@font-face {
  font-family: 'GeomVariable';
  src: local('GeomVariable'), url(fonts/geom/Geom_Variable.ttf) format('truetype');
}

@font-face {
  font-family: 'GeomUltra';
  src: local('GeomUltra'), url(./fonts/geom/Geom_Ultra.ttf) format('truetype');
}

@font-face {
  font-family: 'Jaquel';
  src: local('Jaquel'), url(./fonts/jaquel/Jaquel_Heavy.otf) format('truetype');
}

@font-face {
  font-family: 'Brooklyn';
  src: local('Brooklyn'), url(./fonts/brooklyn/Brooklyn_Heavy.ttf) format('truetype');
}
